/* eslint-disable */
import React from "react";
import Dropzone from "react-dropzone";
import LoadingOverlay from "react-loading-overlay";

const FileUpload = ({
  children,
  disable,
  styleIgnore,
  handleDrop,
  loading
}) => (
  // <LoadingOverlay active={loading} spinner text="Loading your files...">
  <Dropzone
    onDrop={(acceptedFiles, rejectedFiles) =>
      handleDrop(acceptedFiles, rejectedFiles)
    }
    multiple
    disableClick={disable}
    maxSize={10000000}
  >
    {({ getRootProps, getInputProps }) => (
      <div
        {...getRootProps()}
        className={
          styleIgnore ? "browserBtn form-group" : "dropzone form-group"
        }
      >
        {children}
        <input {...getInputProps()} />
      </div>
    )}
  </Dropzone>
  // </LoadingOverlay>
);

export default FileUpload;
