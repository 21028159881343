/* eslint-disable */
import React, { Component } from "react";
import L from "leaflet";
import { toast } from "react-toastify";

class MapFilter extends Component {
  state = {
    showMobileSearchBar: true,
    maxMobileWidth: 768,
    selectAllLabel: "(Select All)"
  };
  autocomplete;
  geocoder;
  marker;

  async componentDidMount() {
    this.marker = new L.marker();
    this.setState({
      showMobileSearchBar: window.innerWidth >= this.state.maxMobileWidth
    });
    window.addEventListener("resize", this.screenWidthChanged);
  }

  screenWidthChanged = () => {
    this.setState({
      showMobileSearchBar: window.innerWidth >= this.state.maxMobileWidth
    });
  };

  handleCropsChange = e => {
    let options = [...this.props.selectedCropGroups];
    if (e.target.checked) {
      if (e.target.value === this.state.selectAllLabel) {
        options = [...this.props.cropGroups];
      } else {
        options.push(e.target.value);
      }
    } else {
      if (e.target.value === this.state.selectAllLabel) {
        options = [];
      } else {
        let index = options.indexOf(e.target.value);
        options.splice(index, 1);
      }
    }
    this.props.setSelectedCropGroups(options);
  };

  toggleMobileSearchBar = value => {
    this.setState(
      {
        showMobileSearchBar:
          typeof value === "boolean" ? value : !this.state.showMobileSearchBar
      },
      () => {
        if (this.state.showMobileSearchBar === true)
          document.getElementById("pac-search").focus();
      }
    );

    document.getElementById("pac-search").value = "";
    //this.props.toggleHideUploadOptionMobileView();
  };

  componentDidUpdate(prevProp) {
    if (prevProp.map !== this.props.map) {
      var input = document.getElementById("pac-search");

      //this.map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(input);
      this.autocomplete = new window.google.maps.places.Autocomplete(input);
      window.google.maps.event.addListener(
        this.autocomplete,
        "place_changed",
        () => {
          let place = this.autocomplete.getPlace();
          if (place.geometry) this.navigateToPlace(this.props.map, place);
        }
      );

      this.geocoder = new window.google.maps.Geocoder();
      document
        .getElementById("search-btn")
        .addEventListener("click", this.handleSearchClick);

      input.addEventListener("keyup", this.handleKeyup);
    }
    if (prevProp.cropGroups !== this.props.cropGroups) {
      this.setState({ selectedCropGroups: [...this.props.cropGroups] });
    }
  }

  handleKeyup = e => {
    let key = e.which || e.keyCode;
    if (key === 13) {
      // 13 is enter
      let address = document.getElementById("pac-search").value;
      this.geocoder.geocode({ address: address }, (results, status) => {
        this.geocodeAddress(results, status, address);
      });
    }
  };

  handleSearchClick = () => {
    let address = document.getElementById("pac-search").value;
    this.geocoder.geocode({ address: address }, (results, status) => {
      this.geocodeAddress(results, status, address);
    });
  };

  geocodeAddress = (results, status, address) => {
    if (status === "OK") this.navigateToPlace(this.props.map, results[0]);
    else
      toast.error(`Maps can't find ${address}`, {
        position: toast.POSITION.BOTTOM_LEFT
      });
  };

  navigateToPlace(map, place) {
    this.toggleMobileSearchBar(false);
    if (place.geometry.viewport) {
      var corner1 = L.latLng(
          place.geometry.viewport.getNorthEast().lat(),
          place.geometry.viewport.getNorthEast().lng()
        ),
        corner2 = L.latLng(
          place.geometry.viewport.getSouthWest().lat(),
          place.geometry.viewport.getSouthWest().lng()
        ),
        bounds = L.latLngBounds(corner1, corner2);

      map.fitBounds(bounds);
    } else {
      map.setView(
        [place.geometry.location.lat(), place.geometry.location.lng()],
        10
      );
    }
    var myIcon = L.icon({
      iconUrl:
        "https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png",
      iconSize: [27, 43]
    });
    map.removeLayer(this.marker);
    var latlng = L.latLng(
      place.geometry.location.lat(),
      place.geometry.location.lng()
    );
    this.marker = new L.marker(latlng, { icon: myIcon }).addTo(map);

    var address = "";
    var placeName = "";
    if (place.address_components) {
      placeName =
        place.address_components[0] && place.address_components[0].long_name;
      address = [
        (place.address_components[0] &&
          place.address_components[0].short_name) ||
          "",
        (place.address_components[1] &&
          place.address_components[1].short_name) ||
          "",
        (place.address_components[2] &&
          place.address_components[2].short_name) ||
          ""
      ].join(" ");
    }

    placeName = place.name ? place.name : placeName;

    this.marker
      .bindPopup("<div><strong>" + placeName + "</strong><br>" + address)
      .openPopup();
  }

  onBlurExit = () => {
    this.setState({ showMobileSearchBar: false });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.screenWidthChanged);
    document
      .getElementById("pac-search")
      .removeEventListener("keyup", this.handleKeyup);
    document
      .getElementById("search-btn")
      .removeEventListener("click", this.handleSearchClick);
  }

  render() {
    return (
      <div className="filter header-navigation">
        <div className="container">
          <nav>
            <ul id="filter" className="nav nav-pills pull-left">
              <li className="has-children ">
                <a href="#">
                  {this.props.selectedYear} <i className="fa fa-caret-down" />
                </a>

                <div style={{ height: "0px", width: "0px" }}>
                  <a href="#">
                    {this.props.selectedYear} <i className="fa fa-caret-down" />
                  </a>
                  <ul className="sub-menu">
                    {this.props.years &&
                      this.props.years.map((val, index) => {
                        return (
                          <li key={val}>
                            <label className="radio-container">
                              {val}
                              <input
                                type="radio"
                                value={val}
                                onChange={() => {
                                  this.props.setSelectedYear(val);
                                }}
                                checked={this.props.selectedYear === val}
                                name="radio"
                              />
                              <span className="checkmark" />
                            </label>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </li>
              <li className="has-children ">
                <a href="#">
                  {this.props.selectedCropGroups.length === 0
                    ? "Select Crops"
                    : this.props.selectedCropGroups.length ===
                      this.props.cropGroups.length
                    ? "All Crops"
                    : this.props.selectedCropGroups.length === 1
                    ? this.props.selectedCropGroups[0]
                    : "Multiple Crops"}{" "}
                  <i className="fa fa-caret-down" />
                </a>

                <div style={{ height: "0px", width: "0px" }}>
                  <a href="#">
                    {this.props.selectedCropGroups.length === 0
                      ? "Select Crops"
                      : this.props.selectedCropGroups.length ===
                        this.props.cropGroups.length
                      ? "All Crops"
                      : this.props.selectedCropGroups.length === 1
                      ? this.props.selectedCropGroups[0]
                      : "Multiple Crops"}{" "}
                    <i className="fa fa-caret-down" />
                  </a>

                  <ul className="sub-menu">
                    <li key={this.state.selectAllLabel}>
                      <label className="selectall">
                        <input
                          type="checkbox"
                          value={this.state.selectAllLabel}
                          onChange={this.handleCropsChange}
                          checked={
                            this.props.selectedCropGroups.length ===
                            this.props.cropGroups.length
                          }
                        />{" "}
                        {this.state.selectAllLabel}
                      </label>
                    </li>
                    {this.props.cropGroups.map(val => {
                      return (
                        <li key={val}>
                          <label>
                            <input
                              type="checkbox"
                              value={val}
                              onChange={this.handleCropsChange}
                              checked={
                                this.props.selectedCropGroups.indexOf(val) !==
                                -1
                              }
                            />{" "}
                            {val}
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>

              <li className="header-search visible-xs">
                <span className="input-group-btn">
                  <button onClick={this.toggleMobileSearchBar} className="btn">
                    <i className="fa fa-search" />
                  </button>
                </span>
              </li>
            </ul>
          </nav>
          <div
            className={
              !this.state.showMobileSearchBar
                ? "header-search pull-left"
                : "header-search pull-left opened"
            }
          >
            <div className="input-group">
              <input
                id="pac-search"
                type="text"
                placeholder="Search..."
                className="form-control"
                style={{ display: "block" }}
              />

              <span className="input-group-btn">
                <button id="search-btn" className="btn">
                  <i className="fa fa-search" />
                </button>
              </span>
            </div>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.toggleMobileSearchBar}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          {/* <br />
          <div className={!this.state.showMobileSearchBar ? "" : "opened"}>
            <div className="input-group">
              <input
                // className="form-control"
                placeholder="Search..."
                type="text"
                id="pac-search"
                hidden={this.state.showMobileSearchBar ? false : true}
              />
              <button id="search-btn" className="btn">
                <i className="fa fa-search" />
              </button>
            </div>
          </div> */}

          {/* {this.state.showMobileSearchBar && (
            <div className="header-search pull-left">
              <div className="input-group">
                <input
                  id="pac-search"
                  type="text"
                  placeholder="Search..."
                  className="form-control"
                />
                <span className="input-group-btn">
                  <button id="search-btn" className="btn">
                    <i className="fa fa-search" />
                  </button>
                </span>
              </div>
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

export default MapFilter;
