import "react-toastify/dist/ReactToastify.css";

import {
  HttpGet_uploadJob_partners,
  SetAuthorizationHeader,
  HttpPost_updateUserLoginDate
} from "./services/APIsevices";
import { Prompt, Redirect, Route, Switch } from "react-router-dom";
import React, { Component } from "react";

import About from "./components/about";
import Home from "./components/home";
import { ImplicitCallback } from "@okta/okta-react";
import LoadingOverlay from "react-loading-overlay";
import Login from "./components/login";
import ManageCrops from "./components/manageCrops";
import ManageFiles from "./components/manageFiles";
import ManageCropYears from "./components/manageCropYears";
import ManageUsers from "./components/manageUsers";
import Navbar from "./components/navbar";
import NotAuthorized from "./components/notauthorized";
import NotFound from "./components/notfound";
import { ToastContainer, toast } from "react-toastify";
import WhyParticipate from "./components/whyparticipate";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { withAuth } from "@okta/okta-react";
import LegalDisclamar from './components/legalComponents/legalDisclamar';
import { RoleEnums } from "./Models/Enums";

class App extends Component {
  state = {
    uploadComponentVisible: false,
    user: null,
    userCanHaveLayers: false,
    userMaximumAllowedZoom: 10,
    reset: false,
    authenticated: false,
    idToken: null,
    accessToken: null,
    isAdmin: null,
    loading: false,
    partners: {},
    hideUploadOptionMobileView: false,
    isAgreementApproved:false,
    userRole: RoleEnums.Anynonms
  };

  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    if (authenticated) {
      let idToken = await this.props.auth.getIdToken();
      let accessToken = await this.props.auth.getAccessToken();
      if (
        idToken &&
        accessToken &&
        (idToken !== this.state.idToken ||
          accessToken !== this.state.accessToken)
      ) {
        let parsedIdToken = this.parseJwt(idToken);
        let parsedAccessToken = this.parseJwt(accessToken);
        let isAdmin = false;
        if (parsedAccessToken && parsedAccessToken.groups && parsedAccessToken.groups.indexOf("IP360-Admin") >= 0) {
          isAdmin = true;
          this.setState({ userRole: RoleEnums.Admin});
        }
        if(parsedAccessToken && parsedAccessToken.groups && parsedAccessToken.groups.indexOf("Everyone") >= 0){
          this.setState({ userRole: RoleEnums.Partener});
        }
        SetAuthorizationHeader(accessToken);
        await HttpPost_updateUserLoginDate(parsedIdToken.email, parsedIdToken.name, isAdmin);
        return {
          authenticated,
          idToken: idToken,
          accessToken: accessToken,
          user: parsedIdToken.name,
          userCanHaveLayers: (this.state.userRole === RoleEnums.Partener || this.state.userRole === RoleEnums.Admin ) ? true : false,
          userMaximumAllowedZoom: 20,
          isAdmin: isAdmin
        };
      }
    } else if (this.state.authenticated) {
      SetAuthorizationHeader(null);
      return {
        authenticated,
        idToken: null,
        accessToken: null,
        user: null,
        userCanHaveLayers: false,
        userMaximumAllowedZoom: 10,
        isAdmin: false
      };
    }
    return null;
  }

  async componentDidMount() {
    var state = await this.checkAuthentication();
    if (state) {
      this.setState(state);
    }
  }

  async componentDidUpdate() {
    var state = await this.checkAuthentication();
    if (state) {
      this.setState(state);
    }
  }

  setUserAuthenticationState = async () => {
    var state = await this.checkAuthentication();
    if (state) {
      this.setState(state);
    }
  };

  login = async () => {
    this.props.auth.login("/");
  };

  logout = async () => {
    window.location.href = "/";
    this.props.auth.logout("/").then(() => {
      window.location.href = "/";
    });
  };

  parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  }

  isLoading = loading => {
    this.setState({ loading });
  };

  resetCrops = reset => {
    this.setState({ reset });
  };

  showHideUploadComponent = () => {
    this.setState({
      uploadComponentVisible: !this.state.uploadComponentVisible
    });
  };

  closeUploadComponent = () => {
    this.setState({ uploadComponentVisible: false });
  };

  getPartners = async () => {
    const partners = await HttpGet_uploadJob_partners();
    this.setState({ partners });
  };

  toggleHideUploadOptionMobileView = () => {
    this.setState({
      hideUploadOptionMobileView: !this.state.hideUploadOptionMobileView
    });
  };

  agreementDisclamar = agreementDisplayed=>{
    this.setState({isAgreementApproved:!agreementDisplayed});
    this.setState({loading:agreementDisplayed})
  }

  render() {
    library.add(faPhone);
    return (
      <LoadingOverlay 
        active={this.state.loading}
        spinner
        text="Loading..."
        style={{ zIndex: 1001 }}
      >
        
        <LegalDisclamar agreementDisc={this.agreementDisclamar}></LegalDisclamar>
        <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
        <div className="page-wrapper">
          <Navbar
            user={this.state.user}
            onUploadClicked={this.showHideUploadComponent}
            onLoginClicked={this.login}
            onLogoutClicked={this.logout}
            isAdmin={this.state.isAdmin}
            authenticated={this.state.authenticated}
            hideUploadOptionMobileView={this.state.hideUploadOptionMobileView}
          />

          <div className="main">
            <div className="map-wrapper">
              <Switch>
                <Route path="/about" component={About} />
                <Route path="/whyparticipate" component={WhyParticipate} />
                <Route path="/implicit/callback" component={ImplicitCallback} />
                <Route path="/not-authorized" component={NotAuthorized} />
                <Route path="/loginForm" component={Login} />
                <Route path="/not-found" component={NotFound} />
                <Route
                  path="/"
                  exact
                  render={() => (
                    <Home
                      user={this.state.user}
                      userCanHaveLayers={this.state.userCanHaveLayers}
                      userMaximumAllowedZoom={this.state.userMaximumAllowedZoom}
                      isAdmin={this.state.isAdmin}
                      authenticated={this.state.authenticated}
                      closeUploadComponent={this.closeUploadComponent}
                      uploadComponentVisible={this.state.uploadComponentVisible}
                      isLoading={this.isLoading}
                      partners={this.state.partners}
                      getPartners={this.getPartners}
                      toggleHideUploadOptionMobileView={
                        this.toggleHideUploadOptionMobileView
                      }
                    />
                  )}
                />

                <Route
                  path="/manage-crops"
                  exact
                  render={() => (
                    <React.Fragment>
                      <Prompt
                        when={this.state.reset}
                        message="You have unsaved changes, are you sure you want to leave?"
                      />
                      <ManageCrops
                        user={this.state.user}
                        ResetFn={this.resetCrops}
                        isAdmin={this.state.isAdmin}
                        setUserAuthenticationState={
                          this.setUserAuthenticationState
                        }
                      />
                    </React.Fragment>
                  )}
                />
                <Route
                  path="/manage-years"
                  exact
                  render={() => (
                    <ManageCropYears
                      isAdmin={this.state.isAdmin}
                      setUserAuthenticationState={
                        this.setUserAuthenticationState
                      }
                    />
                  )}
                />
                <Route
                  path="/manage-users"
                  exact
                  render={() => (
                    <ManageUsers
                      isAdmin={this.state.isAdmin}
                      setUserAuthenticationState={
                        this.setUserAuthenticationState
                      }
                    />
                  )}
                />
                <Route
                  path="/manage-files"
                  exact
                  render={() => (
                    <ManageFiles
                      user={this.state.user}
                      partners={this.state.partners}
                      getPartners={this.getPartners}
                      isAdmin={this.state.isAdmin}
                      setUserAuthenticationState={
                        this.setUserAuthenticationState
                      }
                    />
                  )}
                />
                <Redirect to="/not-found" />
              </Switch>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withAuth(App);
