/* eslint-disable */
export const SortFunction = (field, direction) =>{
    let sort = `${field}-${direction}`;
    switch(sort){
        case "name-asc":
      return (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      };
      case "name-desc":
      return (a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      };
      case "displayed_email-asc":
      return (a, b) => {
        if (a.email < b.email) {
          return -1;
        }
        if (a.email > b.email) {
          return 1;
        }
        return 0;
      };
      case "displayed_email-desc":
      return (a, b) => {
        if (a.email < b.email) {
          return 1;
        }
        if (a.email > b.email) {
          return -1;
        }
        return 0;
      };
      case "role-asc":
      return (a, b) => {
        if (a.role < b.role) {
          return -1;
        }
        if (a.role > b.role) {
          return 1;
        }
        return 0;
      };
      case "role-desc":
      return (a, b) => {
        if (a.role < b.role) {
          return 1;
        }
        if (a.role > b.role) {
          return -1;
        }
        return 0;
      };
      case "is_deleted-asc":
      return (a, b) => {
        if (a.is_deleted < b.is_deleted) {
          return -1;
        }
        if (a.is_deleted > b.is_deleted) {
          return 1;
        }
        return 0;
      };
      case "is_deleted-desc":
      return (a, b) => {
        if (a.is_deleted < b.is_deleted) {
          return 1;
        }
        if (a.is_deleted > b.is_deleted) {
          return -1;
        }
        return 0;
      };
      case "invitationDate-asc":
      return (a, b) => {
        if (a.invitation_date < b.invitation_date) {
          return -1;
        }
        if (a.invitation_date > b.invitation_date) {
          return 1;
        }
        return 0;
      };
      case "invitationDate-desc":
      return (a, b) => {
        if (a.invitation_date < b.invitation_date) {
          return 1;
        }
        if (a.invitation_date > b.invitation_date) {
          return -1;
        }
        return 0;
      };
      case "lastLoginDate-asc":
      return (a, b) => {
        if (a.last_login_date < b.last_login_date) {
          return -1;
        }
        if (a.last_login_date > b.last_login_date) {
          return 1;
        }
        return 0;
      };
      case "lastLoginDate-desc":
      return (a, b) => {
        if (a.last_login_date < b.last_login_date) {
          return 1;
        }
        if (a.last_login_date > b.last_login_date) {
          return -1;
        }
        return 0;
      };
    }
};