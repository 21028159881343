/* eslint-disable */
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import '@okta/okta-signin-widget/dist/css/okta-theme.css';

import React, { Component } from 'react';

import OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in-no-jquery';
import ReactDOM from 'react-dom';

export default class OktaSignInWidget extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidMount() {
        this.widget = new OktaSignIn({
            logo: this.props.config.logo,
            features: {
                rememberMe: true,
                showPasswordToggleOnSignInPage: true,
                registration: true
            },
            registration: {
                click: () => {
                    window.location.href = `https://login.landdb.com/register?referrer=${window.location.href}`;
                }
            },
            baseUrl: this.props.config.baseUrl,
            clientId: this.props.config.client_id,
            redirectUri: this.props.config.redirect_uri,
            authParams: {
                issuer: this.props.config.issuer,
                display: 'page',
                responseType: ['id_token', 'token'],
                scopes: ['openid', 'email', 'profile', 'ip360'],
            },
            i18n: {
                en: {
                    'primaryauth.title': 'Sign in to Land.db'
                }
            },
            idps: this.props.config.idps,
            idpDisplay: 'PRIMARY',
            helpLinks: {
                help: 'http://www.agconnections.com/support',
            }
        });
        this.widget.renderEl({ el: this.myRef.current }, (res) => {
            this.props.onSuccess(res);
            if (res.status === 'SUCCESS') {
                res.forEach((_) => {
                    if (_.idToken) {
                        this.widget.tokenManager.add('id_token', _);
                    } else if (_.accessToken) {
                        this.widget.tokenManager.add('access_token', _);
                    }
                });
            }
        }, this.props.onError);

        var eula = document.createElement('div')
        eula.className = "registration-container";
        eula.style.marginTop = 0;
        eula.innerHTML = `
            <div class="content-container">
                <span class="registration-label">By signing in to this application you agree to the terms of the </span>
                <a title="EULA" target="_blank" aria-label="EULA" class="registration-link" href="https://login.landdb.com/eula">EULA</a>
            </div>`

        var observer = new MutationObserver((mutationsList, observer) => {
            let primaryAuth = document.getElementsByClassName('primary-auth');
            if (primaryAuth.length > 0) {
                primaryAuth[0].appendChild(eula);
                observer.disconnect();
            }
        });
        observer.observe(document.getElementById('auth-container'), { attributes: true, childList: true, subtree: true });
        this.widget.tokenManager.on('expired', function (key, expiredToken) {
            this.widget.tokenManager.refresh(key);
            console.log('Token with key', key, ' has expired:');
            console.log(expiredToken);
        });

        this.widget.tokenManager.on('error', function (err) {
            console.log('TokenManager error:', err);
        });

        this.widget.tokenManager.on('refreshed', function (key, newToken, oldToken) {
            console.log('Token with key', key, 'has been refreshed');
            console.log('Old token:', oldToken);
            console.log('New token:', newToken);
        });
    }

    componentWillUnmount() {
        this.widget.remove();
    }

    render() {
        return <div className="logindiv" >
            <div id="welcome" className="loginwelcome">
                <p className="loginwelcomeparagraph">Welcome to our new sign-in experience. If you need help signing in, please click <a target="_blank" className="loginwelcomeparagraphanchor" href="https://login.landdb.com/instructions">HERE</a> for instructions.</p>
            </div>
            <div id="auth-container" ref={this.myRef} ></div>
        </div>
    }
};