/* eslint-disable */
import React, { Component } from "react";
import { HttpPost_sendInvitation } from "../../services/APIsevices";
import Modal from "react-modal";
import Select from "react-select";
import { toast } from "react-toastify";

Modal.setAppElement('#root');
const roles = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Partner', label: 'Partner' }
  ];

const customStyles = {
    content : {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      minWidth: '330px',
      width: '35%',
      height: 'auto'
    }
  };

class SendInviation extends Component {
    state={
        firstName: "",
        lastName: "",
        email: "",
        selectedRole: null,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        roleError: false
    };

    componentDidUpdate = (prevProp) =>{
        if(prevProp.modalOpen !== this.props.modalOpen){
            this.clearInputData();
        }
    }

    clearInputData = ()=>{
        this.setState({
            firstName: "",
            lastName: "",
            email: "",
            selectedRole: null,
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            roleError: false,
        });
    }

    handleSendInvitation = ()=>{
        this.setState({
            firstNameError: false,
            lastNameError: false,
            emailError: false,
            roleError: false,
        });
        if(this.isDataValid()){
            HttpPost_sendInvitation({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                role: this.state.selectedRole.value
            }).then(()=>{
                toast.success("User has been invited successfully");
                this.props.closeModal();
                this.props.refreshUsersData();
            }).catch(err=>{
                 toast.error(err);
            });
        }
    };

    isDataValid(){
        let isValid = true;
        const {firstName, lastName, email, selectedRole} = this.state;
        let isMailValid = this.validateEmail(email);
        if(!firstName || !lastName || !isMailValid|| !selectedRole){
            this.setState({
                firstNameError: firstName? false: true,
                lastNameError: lastName? false: true,
                emailError: !isMailValid,
                roleError: selectedRole? false: true,
            });
            isValid = false;
        }
        return isValid;
    }

    handleRoleChange = (evt)=>{
        this.setState({selectedRole: evt, roleError: false});
    }

    handleFirstNameChange = (evt)=>{
        this.setState({firstName: evt.target.value, firstNameError: false});
        if(!evt.target.value)
            this.setState({firstNameError: true});
    }

    handleLastNameChange = (evt)=>{
        this.setState({lastName: evt.target.value, lastNameError: false});
        if(!evt.target.value)
            this.setState({lastNameError: true});
    }
    
    handleEmailChange = (evt)=>{
        this.setState({email: evt.target.value, emailError: false});
        if(!this.validateEmail(evt.target.value))
            this.setState({emailError: true});
    }
    
    validateEmail(email) 
    {
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(String(email).toLowerCase());
    }
    render() { 
        return ( <Modal
            isOpen={this.props.modalOpen}
            onRequestClose={this.props.closeModal}
            style={customStyles}
            contentLabel="Example Modal">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <Select options={roles} value={this.state.selectedRole} onChange={ value=>this.handleRoleChange(value)} placeholder="Role" />
                            {this.state.roleError && (<span style={{color:'red'}}>Role is required</span>)}
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <input type="text" placeholder="First Name" value={this.state.firstName} onChange={this.handleFirstNameChange} className="form-control"/>
                            {this.state.firstNameError && (<span style={{color:'red'}}>First name is required</span>)}
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <input placeholder="Last Name" value={this.state.lastName} onChange={this.handleLastNameChange} className="form-control"/>
                            {this.state.lastNameError && (<span style={{color:'red'}}>Last name is required</span>)}
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group">
                            <input placeholder="Email" value={this.state.email} onChange={this.handleEmailChange} className="form-control"/>
                            {this.state.emailError && (<span style={{color:'red'}}>Please enter a valid email</span>)}
                        </div>
                    </div>
                <div className="col-sm-12">
                    <div className="form-group pull-right">
                        <button className="btn" onClick={this.handleSendInvitation}>Send</button>
                        <button className="btn" style={{marginLeft: 10, backgroundColor: '#888f94'}} onClick={this.props.closeModal}>Close</button>
                    </div>
                </div>
            </Modal>);
    }
}
 
export default SendInviation;